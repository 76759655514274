import $ from 'jquery';

require('bootstrap');

$(() => {
  // Navigation scrolls
  $('.navbar-nav li a').on('click', (event) => {
    const $anchor = $(this);
    const nav = $($anchor.attr('href'));
    $('.navbar-nav li').removeClass('active');
    $(this).closest('li').addClass('active');
    if (nav.length) {
      $('html, body')
        .stop()
        .animate(
          {
            scrollTop: $($anchor.attr('href')).offset().top
          },
          1500,
          'easeInOutExpo'
        );
      event.preventDefault();
    }
  });

  // Add smooth scrolling to all links in navbar
  $('.navbar a, a.mouse-hover, .overlay-detail a').on('click', (event) => {
    event.preventDefault();
    const { hash } = event.target;
    $('html, body').animate(
      {
        scrollTop: $(hash).offset().top
      },
      900,
      () => {
        window.location.hash = hash;
      }
    );
  });
});
